import React from 'react'
import PropTypes from 'prop-types'
import ReactToPrint from 'react-to-print'

class ReportContainer extends React.Component {
  state = { isPrinting: false }

  constructor (props) {
    super(props)
    this.componentRef = document.querySelector(props.selector)
  }

  handleOnBeforeGetContent = () => {
    this.componentRef.style.width = '1600px'
    this.setState({ isPrinting: true })
    return new Promise(resolve => setTimeout(resolve, 600))
  }

  handleAfterPrint = () => {
    this.componentRef.style.width = ''
    this.setState({ isPrinting: false })
  }

  render () {
    const { isPrinting } = this.state

    return (
      <ReactToPrint
        onBeforeGetContent={this.handleOnBeforeGetContent}
        onAfterPrint={this.handleAfterPrint}
        content={() => this.componentRef}
        removeAfterPrint
      >
        {this.props.children({ isPrinting })}
      </ReactToPrint>
    )
  }
}

ReportContainer.propTypes = {
  selector: PropTypes.string,
  children: PropTypes.func
}

ReportContainer.defaultProps = {
  selector: '.report'
}

export default ReportContainer
