import React from 'react'
import ReportHeader from 'components/ReportHeader'
import { FormattedMessage } from 'react-intl'

const Header = ({ handlePrint }) => (
  <FormattedMessage id='reports.scrapPareto.name'>
    {text => <ReportHeader name={text} handlePrint={handlePrint} />}
  </FormattedMessage>
)

export default Header
