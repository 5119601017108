import React from 'react'
import {
  ResponsiveContainer,
  ComposedChart,
  Line,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts'
import PropTypes from 'prop-types'

const totalFormatter = total => `${Math.round(total * 1000) / 10.0} %`

const graphStyles = {
  normal: {
    container: { marginBottom: 18 },
    composedChart: { bottom: 120 },
    animation: {},
  },
  printing: {
    container: { marginBottom: 0 },
    composedChart: { bottom: 20 },
    animation: {
      animationDuration: 0,
      isAnimationActive: false,
    },
  },
}

const ParetoReportGraph = ({
  data,
  dataFormatter,
  dataKey,
  GraphLabel,
  GraphTooltip,
  isPrinting,
}) => {
  const styles = graphStyles[isPrinting ? 'printing' : 'normal']

  return (
    <div
      className='report-recharts-container'
      style={{
        width: '100%',
        height: 500,
        marginTop: 18,
        ...styles.container,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          width={600}
          height={400}
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            ...styles.composedChart,
          }}
        >
          <CartesianGrid stroke='#f5f5f5' />
          <XAxis dataKey='id' interval={0} tick={<GraphLabel />} />
          <YAxis
            yAxisId='left'
            orientation='left'
            dataKey={dataKey}
            tickFormatter={dataFormatter}
            tickCount={11}
          />
          <YAxis
            yAxisId='right'
            orientation='right'
            dataKey='total'
            domain={[0, 1]}
            tickFormatter={totalFormatter}
            tickCount={11}
          />
          <Tooltip content={<GraphTooltip />} />
          <Bar
            yAxisId='left'
            dataKey={dataKey}
            barSize={40}
            {...styles.animation}
          >
            {data.map(entry => (
              <Cell
                key={`${entry.month}-${entry.symbol}`}
                fill={
                  'unplanned' in entry
                    ? entry.unplanned
                      ? 'indigo'
                      : 'red'
                    : '#FFB7A5'
                }
              />
            ))}
          </Bar>
          <Line
            yAxisId='right'
            type='monotone'
            dataKey='total'
            stroke='#9E2B0E'
            animationDuration={500}
            {...styles.animation}
            strokeWidth={3}
            dot={{ r: 5, fill: '#fff', strokeWidth: 3, stroke: '#9E2B0E' }}
            activeDot={{ r: 5, fill: '#9E2B0E', strokeWidth: 0 }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

ParetoReportGraph.propTypes = {
  data: PropTypes.array.isRequired,
  dataFormatter: PropTypes.func.isRequired,
  dataKey: PropTypes.string.isRequired,
  GraphLabel: PropTypes.func.isRequired,
  GraphTooltip: PropTypes.func.isRequired,
}

export default ParetoReportGraph
