import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { PrintContextConsumer } from 'react-to-print'
import Loader from 'components/Loader'
import ReportContainer from 'components/ReportContainer'
import ScrapParetoReportHeader from 'reports/scrapPareto/ScrapParetoReportHeader'
import ScrapParetoReportLocations from 'reports/scrapPareto/ScrapParetoReportLocations'
import ScrapParetoReportGraph from 'reports/scrapPareto/ScrapParetoReportGraph'

const ScrapParetoReport = ({ isLoaded, plant }) => {
  if (isLoaded) {
    return (
      <ReportContainer selector='#scrap-report.report'>
        {({ isPrinting }) => (
          <React.Fragment>
            <PrintContextConsumer>
              {({ handlePrint }) => (
                <ScrapParetoReportHeader handlePrint={handlePrint} />
              )}
            </PrintContextConsumer>
            <ScrapParetoReportLocations />
            <ScrapParetoReportGraph isPrinting={isPrinting} />
          </React.Fragment>
        )}
      </ReportContainer>
    )
  } else {
    return <Loader full />
  }
}

ScrapParetoReport.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
  isLoaded: state.init.isFinished,
})

export default connect(mapStateToProps)(ScrapParetoReport)
